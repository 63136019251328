import { forwardRef, Ref } from "react";
import Link, { type LinkProps } from "next/link";

import Icon, { Icons } from "@/components/atoms/Icon";
import { cn } from "@/helpers/className";

interface BaseProps {
  arrow?: boolean;
  as?: "button" | "link" | "externalLink";
  children: React.ReactNode;
  className?: string;
  color?: "green" | "white";
  hasLoader?: boolean;
  icon?: Icons;
  iconClassName?: string;
  isLoading?: boolean;
  target?: string;
}

type ButtonAsButton = BaseProps &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps> & {
    as?: "button";
  };

type ButtonAsLink = BaseProps &
  Omit<LinkProps, keyof BaseProps> & {
    as: "link";
  };

type ButtonAsExternal = BaseProps &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof BaseProps> & {
    as: "externalLink";
  };

export type ButtonProps = ButtonAsButton | ButtonAsExternal | ButtonAsLink;

/**
 * Polymorphic Button component
 * `as` can be either:
 * * `button`: will render a `<button>` tag
 * * `link`: will render a NextJS `<Link>` component
 * * `externalLink`: will render a `<a>` tag
 */
const Button = forwardRef<unknown, ButtonProps>(
  (
    {
      arrow = true,
      color = "green",
      icon,
      iconClassName,
      isLoading,
      className,
      children,
      hasLoader,
      target,
      ...props
    },
    ref,
  ) => {
    const colorClasses = {
      white: "btn-white",
      green: "btn-green",
    };
    const classNames = cn(
      "btn",
      colorClasses[color],
      isLoading && "is-loading",
      className,
    );

    const { as } = props;
    delete props.as;

    const buttonChildren = (
      <>
        {icon && <Icon name={icon} className={cn("h-4 w-4", iconClassName)} />}
        {children}
        {arrow && !isLoading && (
          <span className="btn__arrow">
            <Icon name="arrow-right" className="h-4 w-4" />
          </span>
        )}
        {hasLoader && (
          <span className="btn__loader">
            <Icon name="loader" className="h-4 w-4 animate-spin" />
          </span>
        )}
      </>
    );

    if (as === "link") {
      return (
        <Link
          className={classNames}
          {...props}
          target={target}
          ref={ref as Ref<HTMLAnchorElement>}
        >
          {buttonChildren}
        </Link>
      );
    }

    if (as === "externalLink") {
      return (
        <a
          className={classNames}
          target="_blank"
          rel="noopener noreferrer"
          ref={ref as Ref<HTMLAnchorElement>}
          {...props}
        >
          {buttonChildren}
        </a>
      );
    }

    return (
      <button
        ref={ref as Ref<HTMLButtonElement>}
        className={classNames}
        {...props}
      >
        {buttonChildren}
      </button>
    );
  },
);

export default Button;
